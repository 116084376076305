body {
    a:hover {
        text-decoration: none;
    }
}

:focus, .focus:not(.disabled), .focus:not(:disabled) {
    /*outline: 1px solid $midblue;
      outline-offset: -1px;
      outline-radius: 3px;*/
    outline: none;
    box-shadow: $focus-outline 0 0 1px 1px; }

:disabled, .ig-disabled {
    opacity: 0.33;
    cursor: default !important;
}

.ig-hide {
    display: none !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0  $focus-width $focus-color;
    -moz-box-shadow: 0 0 0  $focus-width $focus-color;
    box-shadow: 0 0 0  $focus-width $focus-color;
}