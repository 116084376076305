@import '../styles/infogix-ui-variables';

body {
  .p-splitbutton {
    display: flex;
    .p-menu .p-menuitem-link {
      display: flex;
      .p-menuitem-text {
        white-space: nowrap;
      }
    }
    button.p-button {
      background-color: $grey-t5;
      color: $black;
      &:hover:not(:disabled) {
        background: $grey-t4;
        color: $black;
      }

      &:active:not(:disabled) {
        background: $grey-t3;
        color: $black;
      }
    }
  }
}