@import "~primeng/resources/components/tree/tree.css";
@import '../styles/infogix-ui-variables';
body .p-tree {
  width: 100%;
  height:100%;
  padding: 0;
  border: none;

  .p-tree-container {
    white-space: nowrap;
    display:inline-block;
    min-width: 100%;
  }

  .p-treenode-children {
    padding:0; // get rid of the default left padding
  }

  &:not(.ig-empty) {
    .p-tree-wrapper {
      height:100%;
    }
  }

  .p-tree-wrapper {
    width: 100%;
  }
  .p-tree-toggler {
    color:$black !important;

    &.pi {
      font-size: 16px;
      text-align: center;
      width: 16px;
    }
  }
  //noinspection CssNoGenericFontName
  .pi-caret-right:before {
    font-family: FontAwesome;
    content: "\f0da"!important;
  }

  //noinspection CssNoGenericFontName
  .pi-caret-down:before {
    font-family: FontAwesome;
    content: "\f0d7"!important
  }

  .p-tree-container .p-treenode {
    padding:0;
    .p-treenode-content {
      display: inline-flex;
      align-items: center;
      line-height: 18px;
      height: 40px;
      min-width: 100%;
      border-radius: 0;

      &:focus {
        border:2px solid $purple-t4;
        box-shadow: none;
      }
      .ig-button {
        margin:0 8px;
        background: transparent;
        &:hover:not(:disabled), &.ig-state-hover {
          background: $grey-t4;
        }
        &:active:not(:disabled), &.ig-state-active {
          background: $grey-t3;
        }
        &:focus, &.ig-state-focus{
          box-shadow: 0 0 0 2px $purple-t4
        }
      }

      .p-tree-toggler {
        color:$black;
        margin-right:0;
        min-width: 24px;
        width:24px;
        height: 24px;
        line-height:22px;
      }

      .row-check-box {
        margin:0 2px 0 4px;
      }

      .p-treenode-icon {
        margin-right:4px;
        font-size: 16px;
        height:16px;
        min-width: 16px;
        &.leaf {
          margin-left:13px;
        }
      }

      .p-treenode-label {
        display:flex;
        flex:1;
        > span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.p-treenode-selectable {
        .p-treenode-label.p-highlight,
        .p-treenode-label:not(.p-highlight):hover {
          background-color: inherit;
          color: inherit;
        }
      }

      &.p-treenode-content-selected, &.p-highlight {
        background-color: $list-selected-bg-color;
        color: $black;
      }

      &.p-treenode-selectable:not(.p-highlight):hover,
      &:not(.p-treenode-content-selected):hover {
        background-color: $list-hover-bg-color;
      }

      .p-checkbox .p-checkbox-icon {
        margin-top:0;
      }

    }
  }
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-bottom: 2px solid $grey-t5;
}
