@import './infogix-ui-variables.scss';

body .p-disabled, body .p-component:disabled {
  opacity: $field-disabled-opacity;
  filter: alpha(opacity=50);
}

input.p-inputtext {
  font-family: $field-font-family !important;
  font-size: 14px !important;
  font-weight: normal;

  &::placeholder {
    color: $placeholder-color;
  }

  &:focus {
    box-shadow: none;
  }
}

body .p-inputtext:enabled:focus:not(.p-state-error) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: $focus-outline 0 0 1px 1px;
}

body .p-inputtext.disabled {
  opacity: 1 !important;
  background: $grey-xxlight !important;
  color: $grey-light !important;
}

body .p-menu .p-menuitem-link:hover,
body .p-menu .p-menuitem-link:not(.p-disabled):hover,
body .p-menubar .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-megamenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover {
  background-color: $list-hover-bg-color;
}

body .p-calendar {
  position: relative;
  display: inline-flex;
}

body .p-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
body p-autoComplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
body p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
body p-chips.ng-dirty.ng-invalid > .p-inputtext,
body p-inputMask.ng-dirty.ng-invalid > .p-inputtext,
body p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
body p-radioButton.ng-dirty.ng-invalid .p-radiobutton-box,
body p-inputSwitch.ng-dirty.ng-invalid .p-inputswitch,
body .p-listbox.ng-dirty.ng-invalid .p-inputtext,
body p-multiSelect.ng-dirty.ng-invalid > .p-multiselect,
body p-spinner.ng-dirty.ng-invalid > .p-inputtext,
body p-selectButton.ng-dirty.ng-invalid .p-button,
body p-toggleButton.ng-dirty.ng-invalid .p-button {
  border: 1px solid #bdbfc6;
}


body .p-corner-all, body .ui-corner-all {
  -moz-border-radius: $field-border-radius;
  -webkit-border-radius: $field-border-radius;
  border-radius: $field-border-radius;
}

body .p-corner-top {
  -moz-border-radius-topleft: $field-border-radius;
  -webkit-border-top-left-radius: $field-border-radius;
  border-top-left-radius: $field-border-radius;
  -moz-border-radius-topright: $field-border-radius;
  -webkit-border-top-right-radius: $field-border-radius;
  border-top-right-radius: $field-border-radius;
}

body .p-corner-bottom {
  -moz-border-radius-bottomleft: $field-border-radius;
  -webkit-border-bottom-left-radius: $field-border-radius;
  border-bottom-left-radius: $field-border-radius;
  -moz-border-radius-bottomright: $field-border-radius;
  -webkit-border-bottom-right-radius: $field-border-radius;
  border-bottom-right-radius: $field-border-radius;
}

body .p-corner-left {
  -moz-border-radius-topleft: $field-border-radius;
  -webkit-border-top-left-radius: $field-border-radius;
  border-top-left-radius: $field-border-radius;
  -moz-border-radius-bottomleft: $field-border-radius;
  -webkit-border-bottom-left-radius: $field-border-radius;
  border-bottom-left-radius: $field-border-radius;
}

body .p-corner-right {
  -moz-border-radius-topright: $field-border-radius;
  -webkit-border-top-right-radius: $field-border-radius;
  border-top-right-radius: $field-border-radius;
  -moz-border-radius-bottomright: $field-border-radius;
  -webkit-border-bottom-right-radius: $field-border-radius;
  border-bottom-right-radius: $field-border-radius;
}

body .p-dropdown .p-dropdown-label.p-placeholder, body .p-inputtext::placeholder {
  color: $placeholder-color
}

body .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 $focus-width $focus-color;
  -moz-box-shadow: 0 0 0 $focus-width $focus-color;
  box-shadow: 0 0 0 $focus-width $focus-color;
}

body .p-contextmenu .p-menuitem-link:focus {
  box-shadow: 0 0 0 $focus-width $focus-color;
}

body .p-menu .p-menuitem-link:focus {
  box-shadow: 0 0 0 $focus-width $focus-color;
}

.ql-container {
  font-family: $field-font-family !important;
  font-size: 14px !important;
  font-weight: normal;
}

body {
  //-----------------
  // button
  //-----------------
  .p-button:enabled:active {
    color: $black;
    background: $grey-t3;
    border-color: $grey-t3;
  }

  .p-button:enabled:hover {
    background: $grey-t4;
    color: $black;
    border-color: $grey-t4;
  }

  .p-button {
    background: $grey-t5;
    border: 1px solid $grey-t5;
    color: $black;
  }

  //-----------------
  // checkbox
  //-----------------
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 $focus-width $focus-color;
    border-color: $purple;
  }

  //-----------------
  // date picker
  //-----------------
  .p-datepicker table td > span.p-highlight {
    background: $purple;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    border: 2px solid $purple-t4;
    outline: none;
    box-shadow: none;
    outline-offset: 0;
    background: gray;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: $purple;
  }

  //-----------------
  // Text Area
  //-----------------
  textarea[pinputtextarea] {
    padding: 4px;
    font-family: $field-font-family !important;
    font-size: 14px !important;
    font-weight: normal;

    &::placeholder {
      color: $placeholder-color;
    }

    &:enabled:hover:not(.p-state-error) {
      border-color: $field-border-color-hover;
    }

    &:enabled:focus:not(.p-state-error) {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 $focus-width $focus-color;
      -moz-box-shadow: 0 0 0 $focus-width $focus-color;
      box-shadow: 0 0 0 $focus-width $focus-color;
      border-color: $focus-border-color;
    }

    border: 1px solid $field-border-color;
    border-radius: 3px;
  }

  //-----------------
  // Toast
  //-----------------
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {

  }
  .p-toast .p-toast-message {
    &.p-toast-message-info {
      color: $purple;
      background: $purple-t4;
      .p-toast-message-icon,.p-toast-icon-close {
        color: $purple;
      }
    }

    &.p-toast-message-success {
      color: $green;
      background: $green-t4;
      .p-toast-message-icon,.p-toast-icon-close {
        color: $green;
      }
    }

    &.p-toast-message-warn {
      color: $orange;
      background: $orange-t4;
      .p-toast-message-icon,.p-toast-icon-close {
        color: $orange-s1;
      }
    }

    &.p-toast-message-error {
      color: $red;
      background: $red-t4;
      .p-toast-message-icon,.p-toast-icon-close {
        color: $red;
      }
    }
  }


}

body p-treeselect {
  // widen the dropdown to occupy whole width
  div.p-inputwrapper {
    width: 100%;
  }
}
// eliminate the blue circle around the expand collapse icon of selected group
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;  // was creating blue circle shadow
}

// style pick list to match the one from ExtJS
.p-picklist {

  // headers
  & .p-picklist-header {
    border: 0;
    padding: 0.5rem;
    margin-bottom: 4px;

    &  .p-picklist-title {
      font-weight: 400;
      font-family: $field-font-family;
      font-size: 17px;
    }
  }

  // filter
  & .p-picklist-filter-container {
    padding: 0;
    border: 0;

    & .p-picklist-filter-input {
      margin-bottom: 4px;
    }
  }

  // list items
  & .p-picklist-list {

    & .p-picklist-item.p-highlight {
      color: $black;
      background: $list-selected-bg-color;
    }

    & .p-picklist-item:focus {
      box-shadow: none;
    }

    &:not(.cdk-drop-list-dragging) {
      & .p-picklist-item:not(.p-highlight):hover {
        background: $list-hover-bg-color;
      }

      & .p-picklist-item.p-highlight:hover {
        background: $list-hover-bg-color;
      }
    }
  }

  // toolbar between the lists
  & .p-picklist-buttons {
    padding: 0.571rem;

    &.p-button {
      background: $grey-t5;
      border: 1px solid $grey-t5;
      color: $black;

      &.p-button-icon-only {
        width: 1.8rem;
        padding: 0.35rem 0;
      }
    }
  }
}





