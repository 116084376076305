///////////////////////////////////////////////////////////////////////////////////
// Popup Menu
///////////////////////////////////////////////////////////////////////////////////
@import '../styles/infogix-ui-variables';

.ig-popup-menu {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  background: #fff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.33) 0 1px 3px;
  pointer-events: all;

  &.ig-inline {
    position: relative;
  }

  ul {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;

    & > li {
      display: table-row;
      cursor: pointer;
      user-select: none;

      &.ig-disabled, &.label {
        cursor: default;
        opacity: 0.33;
        pointer-events: none;
      }

      &:not(.ig-disabled):not(.ig-separator) {
        &:hover, &.hover {
          background: $list-hover-bg-color;
        }
        &:active, &.active {
          background: $list-selected-bg-color;
        }
      }

      & > span {
        display: table-cell;
        line-height: 40px;
        padding-left: 20px;
        padding-right: 40px;
      }
    }
  }
}