@import '../styles/infogix-ui-variables';

body {
  .p-togglebutton {
    &.p-button {
      border:none;
      background: $grey-t5;
      color:$black;
      &.p-button-icon-only {
        width:32px;
        height:32px;
        padding:0.429rem 1rem;
      }
      &.p-highlight {
        background: $grey-t3;
        color:$black;
        .p-button-icon-left {
          color:inherit;
        }
      }
      &.p-highlight:hover {
        border:none;
        background: $grey-t4;
        color:$black;
        .p-button-icon-left {
          color:inherit;
        }
      }
    }
    border:none;
    background: $grey-t5;
    color:$black;

    &:not(.p-disabled):not(.p-highlight):hover {
      border:none;
      background: $grey-t4;
      color:$black;
    }
  }
}